'use strict';

require("core-js/modules/es.array.flat.js");

require("core-js/modules/es.array.unscopables.flat.js");

var implementation = require('./implementation');

module.exports = function getPolyfill() {
  return Array.prototype.flat || implementation;
};