'use strict';

var GetIntrinsic = require('get-intrinsic');

var $TypeError = GetIntrinsic('%TypeError%'); // http://ecma-international.org/ecma-262/5.1/#sec-9.10

module.exports = function CheckObjectCoercible(value, optMessage) {
  if (value == null) {
    throw new $TypeError(optMessage || 'Cannot call method on ' + value);
  }

  return value;
};